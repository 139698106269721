import { useDispatch, useSelector } from "react-redux";
import { selectManyAttachments } from "../attachmentsSlice";
import { showAttachment } from "../interfaceSlice";


export function LineAttachments ({ attachmentIds }) {
  const dispatch = useDispatch(),
        attachments = useSelector(state => selectManyAttachments(state, attachmentIds))
                        // During an upload the attachment is removed from the attachments
                        // slice, before the score slice is updated.
                        .filter(attachment => attachment !== undefined);
  
  return <>
    &nbsp;[{ attachments.map((attachment) => <span key={ attachment.id} className="attachment--link" onClick={ (e) => {
      e.stopPropagation();
      e.preventDefault(); // Prevent toggling of details tag.
      dispatch(showAttachment(attachment.id))
    } }>{ attachment.title }</span>) }]
  </>;
}

export default LineAttachments;