import { useDispatch, useSelector } from "react-redux"
import { selectAttachment } from "../attachmentsSlice";
import { closeAttachment } from "../interfaceSlice";

const ATTACHMENT_TYPES = {
  IMAGE: 'image',
  YOUTUBE_VIDEO: 'youtube_video',
  VIMEO_VIDEO: 'vimeo_video',
  PDF: 'pdf',
  VIDEO: 'video',
  AUDIO: 'audio'
}


const MIMETYPES = {
  // IMAGE: [
  //   'image/jpeg',
  //   'image/png',
  //   'image/heif',
  //   'image/heic',
  //   'image/gif'
  // ],
  PDF: [
    'application/pdf',
    'application/x-pdf',
    'application/x-bzpdf',
    'application/x-gzpdf'
  ],
  VIDEO: [
    'video/ogg',
    'video/avi',
    'video/mp4',
    'video/x-matroska',
    'video/avi',
    'video/msvideo',
    'video/x-msvideo',
    'video/3gpp',
    'video/webm'
  ],
  AUDIO: [
    'audio/mpeg',
    'audio/MPA',
    'audio/mpa-robust',
    'application/ogg', 
    'audio/ogg',
    'audio/vorbis',
    'audio/vorbis-config',
    'audio/m4a',
    'audio/vnd.wave',
    'audio/wav',
    'audio/wave',
    'audio/webm',
    'audio'
  ]
}


const deriveAttachmentType = (attachment) => {
  const mime = attachment.type,
        url = attachment.url;
  if (mime) {
    if (mime.startsWith('image/')) {
      return ATTACHMENT_TYPES.IMAGE;
    }
    else if (mime.startsWith('video/') || MIMETYPES.VIDEO.indexOf(mime) > -1) {
      return ATTACHMENT_TYPES.VIDEO;
    }
    else if (mime.startsWith('audio/') || MIMETYPES.AUDIO.indexOf(mime) > -1) {
      return ATTACHMENT_TYPES.AUDIO;
    }
    else if (mime == 'application/pdf'
      || mime == 'application/x-pdf'
      || mime == 'application/x-bzpdf'
      || mime == 'application/x-gzpdf'
    ) {
      return ATTACHMENT_TYPES.PDF;
    }
  }
  else if (url) {
    // switch between url types
  }
}

const AttachmentPreview = ({ attachment }) => {
  const type = deriveAttachmentType(attachment);

  switch (type) {
    case ATTACHMENT_TYPES.IMAGE:
      return <div className="attachment-preview--attachment" data-type="image">
        <img src={ attachment.url } />
      </div>
  
    case ATTACHMENT_TYPES.AUDIO:
      return <div className="attachment-preview--attachment" data-type="audio">
        <audio controls>
          <source src={ attachment.url } type={ attachment.type } />
        </audio>
      </div>

    case ATTACHMENT_TYPES.VIDEO:
      return <div className="attachment-preview--attachment" data-type="video">
        <video controls>
          <source src={ attachment.url } type={ attachment.type } />
        </video>
      </div>

    case ATTACHMENT_TYPES.PDF:
      return <div className="attachment-preview--attachment" data-type="pdf">
        <object data={ attachment.url } type="application/pdf" className="pdf"></object>
      </div>

    default:
      return <a href={ attachment.url }>{ attachment.title }</a>
  }
}

export default function Attachment ({ attachmentId }) {
  const dispatch = useDispatch(),
        attachment = useSelector(state => selectAttachment(state, attachmentId));

  return <dialog className="attachment-preview" open>
    <AttachmentPreview attachment={ attachment } />
    <button className="attachment-preview--close" onClick={ () => dispatch(closeAttachment()) }>Close</button>
  </dialog>
}