import uuidv4 from './uuid'

import { FETCH_STATUSES } from '../features/score/constants'

export function newAttachment () {
  return {
    id: uuidv4(),  // null | string
    url: null,   // null | string
    title: null, // null | string,
    type: null, // null | string
    status: FETCH_STATUSES.IDLE,
    file: null, // null | File
    attachment: null,
  }
}