import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import scoreAPI from './scoreAPI';
import { FETCH_STATUSES, FETCH_STATUS_IDLE } from './constants';
import { selectAttachment, selectAttachmentsIdsToUpload, uploadAttachment } from './attachmentsSlice';
import { replaceAttachmentId } from './linesSlice';

/* Score shape
  title: "Untitled",
  mainline: {}, // Line
  stage_set: "aucune",
  setting: '',  // String
  duration: '', // String
  genre: '',  // String
  tags: '', // String?
  is_editable: bool,
  score_type: 0,
  score_author: "",
  performance_author: "",
  presentation: "",
  effectif: "",
  language: "",
  permissions: {},
  shared_with: [],
  lines: {
    str:lineId: Line
  }
*/

/* 
  Attachment Shape
  {
    id: null,  // null | string
    url: "",   // null | string
    title: "", // null | string
    attachment: null,
  }

*/
export const fetchScoreById = createAsyncThunk(
  'scores/fetchScoreByIdStatus',
  async (scoreId) => {
    const response = await scoreAPI.fetchScoreById(scoreId);
    return response;
  }
)

export const putScore = createAsyncThunk(
  'scores/putScore',
  async (_, { getState, dispatch, rejectWithValue }) => {
    // First upload attachments
    const attachmentsIdsToUpload = selectAttachmentsIdsToUpload(getState()),
          failedAttachmentUploads = [];
    
    for (let i=0; i < attachmentsIdsToUpload.length; i++) {
      // Upload attachment. A new object will be created in the attachments slice
      // it should return the new id
      try {
        const uploadResult = await dispatch(uploadAttachment(attachmentsIdsToUpload[i])).unwrap();
        dispatch(replaceAttachmentId({
          oldAttachmentId: attachmentsIdsToUpload[i],
          newAttachmentId: uploadResult
        }));
      }
      catch (error) {
        // Log error
        // Add to failed upload list, include it in the interface.
        failedAttachmentUploads.push(attachmentsIdsToUpload);
      }
    }
    
    if (failedAttachmentUploads.length > 0) {
      const attachmentNames = failedAttachmentUploads.map((id) => selectAttachment(getState(), id).filename);
      return rejectWithValue(`Saving failed. Could not upload some attachments (${ attachmentNames.join(', ') })`)
    }
    else {
      const state = getState();
      const lines = state.editor.present.lines.entities;

      // Make a copy of the score which can be modified
      // and sent to the server
      const score = {...state.editor.present.score.score};
      delete score.lines;

      const restructureLine = (lineId) => {
        let line = { ...lines[lineId] };
        line.sublines = line.sublines.map(lineId => restructureLine(lineId));
        line.attachments = line.attachments.map(attachmentId => selectAttachment(state, attachmentId));
        return line;
      }
      
      score.mainline = restructureLine(score.mainline);

      return await scoreAPI.putScore(score)
        .then((d) => d)
        .catch(e => {
          return rejectWithValue(`Saving failed: ${ e }`)
        })
    }
  }
)

const initialState = {
  score: null,
  /** Index which holds whether lines are shown or not,  */
  expanded: [],
  fetch: {
    status: FETCH_STATUSES.IDLE,
    error: null
  },
  put: {
    status: FETCH_STATUSES.IDLE,
    error: null
  }
};


export const scoreSlice = createSlice({
  name: 'score',
  initialState,
  reducers: {
    setScoreMeta: (state, action)=> {
      state.score = {
        ...state.score,
        ...action.payload
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchScoreById.pending, (state, action) => {
        state.fetch.status = FETCH_STATUSES.PENDING;
      })
      .addCase(fetchScoreById.fulfilled, (state, action) => {
        state.fetch.status = FETCH_STATUSES.FULFILLED;
        state.score = action.payload;
        state.expanded = Object.fromEntries(Object.keys(state.score.lines).map((id) => ([ id, false ])));
      })
      .addCase(fetchScoreById.rejected, (state, action) => {
        state.fetch.status = FETCH_STATUSES.FAILED;
        state.fetch.error = action.error.message;
      })
      .addCase(putScore.pending, (state, action) => {
        state.put.status = FETCH_STATUSES.PENDING;
        state.put.error = null;
      })
      .addCase(putScore.fulfilled, (state, action) => {
        state.put.status = FETCH_STATUSES.FULFILLED;
        state.put.error = null;
      })
      .addCase(putScore.rejected, (state, action) => {
        state.put.status = FETCH_STATUSES.FAILED;
        state.put.error = action.payload;
      })
  }
});

export const selectScore = (state) => state.editor.present.score.score;

export const { setScoreMeta } = scoreSlice.actions;

export default scoreSlice.reducer;