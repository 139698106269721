import { React, useState } from 'react';
import { selectScore } from './scoreSlice';
import { useSelector } from 'react-redux';
import { translate as t } from '../../utils/translate';
import { ScoreMetaForm } from './ScoreMetaForm';

function formatTimestamp(timestring) {
  var d = new Date(timestring);
  return d.getDate()
      + '-' + (d.getMonth() + 1)
      + '-' + d.getFullYear()
      + ' ' + d.getHours()
      + ':' + d.getMinutes();
} 

export function ScoreMetaContent () {
  const score = useSelector(selectScore);
  return <>
    <dl className="score-meta">
      {score.presentation && <>
        <dt className="score-meta__term">{ t('Présentation') }</dt>
        <dd className="score-meta__def">{ score.presentation }</dd>
      </>}
      {score.stage_set && <>
        <dt className="score-meta__term">{ t('Mise') }</dt>
        <dd className="score-meta__def">{ score.stage_set.replace(/\n/g, "<br />") }</dd>
      </>}
    </dl>

    <dl className="score-meta">
      {score.performance_author && (score.score_type === 1 || score.score_type === 3) && <>
        <dt className="score-meta__term">{ t('Auteur de la performance') }</dt>
        <dd className="score-meta__def">{ score.performance_author }</dd>
      </>}
      {score.score_author && <>
        <dt className="score-meta__term">{ t('Auteur de la partition') }</dt>
        <dd className="score-meta__def">{ score.score_author }</dd>
      </>}

      {score.score_type && <>
        <dt className="score-meta__term">{ t('Type de partition') }</dt>
        <dd className="score-meta__def">
            { ((score_type) => {
              switch (score_type) {
              case 1: return t('transcription');
              case 2: return t('prescription');
              case 3: return t('traduction');
              }
            })(score.score_type)}
        </dd>
      </>}
      {score.duration && <>
        <dt className="score-meta__term">{ t('Durée') }</dt>
        <dd className="score-meta__def">{ score.duration }</dd>
      </>}
    </dl>

    {score.tags && <>
        <ul className="tags">
          { score.tags.map(tag => (
            <li className="tags__item">#{ tag }</li>
          ))}
        </ul>
    </>}

    <dl className="score-meta">
        <dt className="score-meta__term score-meta__term--inline">{ t('Créée le') }</dt>
        <dd className="score-meta__def">{ formatTimestamp(score.created_at) }</dd>
        <dt className="score-meta__term score-meta__term--inline">{ t('Modifiée le') }</dt>
        <dd className="score-meta__def">{ formatTimestamp(score.updated_at) }</dd>
    </dl>

    <p>
      {score.is_public ? t('Cette partition est publique') : t('Cette partition est privée')}
    </p>
  </>
}

export function ScoreMeta () {
  const [formVisible, setFormVisible] = useState(false),
        score = useSelector(selectScore);
  
  return <>
    { score && 
        <details class="panel--score-meta" open>
          <summary style={{ writingMode: 'vertical-lr', padding: '2ch 1ch' }}>
            { t('infos') }
          </summary>
          <section className="content">
              <button className="js-edit score-edit" onClick={ () => setFormVisible(true)}>{ t('Éditer') }</button>
              {formVisible && <ScoreMetaForm onClose={ () => setFormVisible(false) } />}
              {!formVisible && <ScoreMetaContent />}
          </section>
        </details>
    }
  </>

};