import { React } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HelpShortcut from '../../utils/HelpShortcut';
import { translate as t } from '../../utils/translate';
import { selectScore } from './scoreSlice';
import { setScoreMeta } from './scoreSlice';

export function ScoreMetaForm ({onClose}) {
  const score = useSelector(selectScore),
        dispatch = useDispatch();
  return <form onSubmit={ (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const payload = Object.fromEntries(formData.entries());
    payload['tags'] = (payload['tags']) ? payload['tags'].split(',') : [];
    dispatch(setScoreMeta(payload));
    onClose();
  }}>
    {/* <!-- Title --> */}
    <label>
      <HelpShortcut target="titre">{ t('Titre') }</HelpShortcut>
      <input type="text" name="title" defaultValue={ score.title } required />
    </label>

    {/* <!-- description --> */}
    <label>
      <HelpShortcut target="presentation">{ t('Présentation') }</HelpShortcut>
      <textarea name="presentation" defaultValue={ score.presentation }></textarea>
    </label>

    {/* <!-- Setting --> */}
    <label>
      <HelpShortcut target="mise">{ t('Mise') }</HelpShortcut>
      <textarea name="stage_set" defaultValue={ score.stage_set }></textarea>
    </label>

    {/* <!-- Performance author --> */}
    <label>
      <HelpShortcut target="auteur-de-la-partition">{ t('Auteur de la performance') }</HelpShortcut>
      <input type="text" name="performance_author" defaultValue={ score.performance_author } disabled={ (score.score_type === 2) } />
    </label>

    {/* <!-- Score author --> */}
    <label>
      <HelpShortcut target="auteur-de-la-partition">{ t('Auteur de la partition') }</HelpShortcut>
      <input type="text" name="score_author" defaultValue={ score.score_author } />
    </label>

    {/* <!-- score_type = models.PositiveSmallIntegerField(default=1, choices=SCORE_TYPE_CHOICES) --> */}
    <label>
      <HelpShortcut target="type-de-partition">{ t('Type') }</HelpShortcut>
      <select name="score_type" defaultValue={ score.score_type }>
          <option value="0">{ t('Indéterminé') }</option>
          <option value="1">{ t('Transcription') }</option>
          <option value="2">{ t('Prescription') }</option>
          <option value="3">{ t('Traduction') }</option>
      </select>
      {/* <!-- <input type="text" name="score_type" value="{ score_type }"> --> */}
    </label>

    {/* <!-- effectif = models.CharField(blank=True, max_length=255) --> */}
    <label class="label-text js-help" dataName="effectif" data-href="#effectif">
      <HelpShortcut target="effectif">{ t('Effectif') }</HelpShortcut>
    </label>

    {/* <!-- Duration --> */}
    <label>
      <HelpShortcut target="duree">{ t('Durée') }</HelpShortcut>
      <input type="text" name="duration" defaultValue={ score.duration } />
    </label>

    {/* <!-- Tags --> */}
    <label>
      <HelpShortcut target="tags">{ t('Tags') } ({ t('séparer par une virgule') })</HelpShortcut>
      <input type="text" name="tags" defaultValue={ score.tags ? score.tags.join(', ') : '' } />
    </label>

    {/* <!-- Language --> */}
    <label>
      <HelpShortcut target="langue">{ t('Langue') }</HelpShortcut>
      <input type="text" name="language" defaultValue={ score.language } />
    </label>

    {/* <!-- Permissions --> */}
    <label>
      <input type="checkbox" name="is_public" defaultChecked={ score.is_public } />
      <HelpShortcut target="nom-de-laxe">{ t('Publiée')  }</HelpShortcut>
    </label>
{/* 
    <HelpShortcut target="nom-de-laxe">{ t('Partager avec') }</HelpShortcut>
    <div id="share_with"></div> */}

    <button type="button" data-name="close" onClick={ onClose }>{ t('Annuler') }</button>
    <button type="submit">OK</button>
  </form>
}