import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Score } from '../score/Score'
import { ScoreList } from "../score/ScoreList";
import { MyScoreList } from "../score/MyScoreList";
import { selectPanel, setPanel } from "./organonSlice";
import { LanguageSelector } from "../score/LanguageSelector";
import { translate as t } from '../../utils/translate';
import { selectScore } from "../score/scoreSlice";
import { ScoreMeta } from "../score/ScoreMeta";

export default function Organon () {
  const panel = useSelector(selectPanel),
        dispatch = useDispatch(),
        score = useSelector(selectScore);

  if (panel == 'index') {
    return <>
      <div style={ { width: '100%', margin: '2ch', maxWidth: '800px' } } >
        <LanguageSelector />
        <a href="https://organon.pw" style={ { color: 'white', fontSize: '125%' } }>← Organon / Notation W v1</a>
        <h1>Notation W</h1>
        <div style={ { display: 'flex' } }>
          <div style={ { flex: '1 1'} } >
            <h2>{ t('Partitions') }</h2>
            <ScoreList />
          </div>
          <div style={ { flex: '1 1'} }>
            <h2>{ t('Mes Partitions') }</h2>
            <MyScoreList />
          </div>
        </div>
      </div>
    </> 
    
  }
  if (panel == 'score') {
    return <div style={{ position: 'absolute', inset: '2ch 0 2ch 2ch', display: 'grid', gridTemplateColumns: '1fr min-content min-content' }}>
      <div style={{ overflow: 'auto', position: 'relative' }}>
        {/* <button style={{ marginBottom: '4ch' }} onClick={ () => dispatch(setPanel('index')) }>← Back to index.</button> */}
        <a href={ `https://organon.pw/partitions/${score?.id}` } style={ { color: 'white', fontSize: '125%' } }>← Organon / Notation W v1</a>
        <LanguageSelector />
        <Score />
      </div>
      <ScoreMeta />
      <details id="helpPanel" style={{ margin: 0, maxWidth: 'initial', position: 'relative' }}>
        <summary style={{ writingMode: 'vertical-lr', padding: '2ch 1ch' }}>help</summary>
        <iframe style={{ position: 'absolute', inset: '0px 0px 0px 50px', height: '100%', width: 'calc(100% - 55px)', border: 'none' }} src="https://organon.pw/pages/en/aide/"></iframe>
      </details>
    </div>
  }

}
